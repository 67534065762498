/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
angular.module('app').controller('adminCompanyEditCtrl', function ($scope, $rootScope,$stateParams, $state, $translate, PrimaGazCompanySrv, ngDialog, Notification) {
    $scope.company  = {};

    var query = {
        query: {
            _id: $stateParams.companyId
        },
        options: {
            populate: [
                {
                    path: '_company_owner',
                    select: 'name'
                },
                {
                    path: '_ctry',
                    select: 'name'
                }
            ]

        }
    };
    PrimaGazCompanySrv.query(query, 1, 1, function (response) {
       if(response && response.result)
        $scope.company = response.result[0];
    }, function (err) {
        console.log(err);
    });


    $scope.save = function () {
        $scope.company._ctry = $scope.company._ctry.code;
        PrimaGazCompanySrv .updateById($stateParams.companyId,$scope.company, function (data) {
            if (data && data.success) {
                Notification.success({
                    title : $translate.instant("UPDATE"),
                    message : $translate.instant("ALERT_COMPANY_UPDATED",{name:$scope.company.name})
                });
                $state.go('app.admin.companies');
                $scope.company  = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.company.name +"!"
                });
                $scope.company  = {};
            }
        }, function (data) {
            console.log(data);
        });
    };

$scope.cancel = function () {
    $scope.company = {};
    $state.go('app.admin.companies');
};

});