/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
angular.module('app').controller('adminRoleCtrl', function ($scope, $rootScope, $state, $translate, PrimaGazRoleSrv, ngDialog, Notification) {
  $scope.role = {};
  $scope.itemsByPage = 5;
  $scope.search = {};

  $scope.loadRoles = function (tableState, ctrl) {
    if (!$scope.stCtrl && ctrl) {
      $scope.stCtrl = ctrl;
    }

    if (!tableState && $scope.stCtrl) {
      $scope.stCtrl.pipe();

      return;
    }
    var start = 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
    var number = $scope.itemsByPage;
    var query = {
      options: {}
    };
    if (tableState.pagination) {
      var pagination = tableState.pagination;
      number = pagination.number || $scope.itemsByPage;
      start = pagination.start / number + 1 || 1;
    }
    if (tableState.sort && tableState.sort.predicate) {
      query.options.sortBy = {};

      var predicate = tableState.sort.predicate;
      query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
    }

      PrimaGazRoleSrv.query(query, number, start, function (data) {
      if (data.success) {
        $scope.roles = data.result;
        $scope.roleCount = data.total_count;
        tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
        tableState.pagination.totalItemCount = data.total_count;
        $scope.tableState = tableState;
      } else {
        console.error(data.error);
      }
    }, function (data) {
      console.log(data);
    });
  };
    /* delete role*/
  $scope.remove = function (role) {
    ngDialog.openConfirm({template: 'app/commons/dialogs/confirm.dlg.html', overlay: true, showClose: true, controller: ['$scope', function ($scope) {
                // controller logic
      $scope.title = $translate.instant("ROLE_DELETE_CONFIRM_TITLE", {name: role._id});
      $scope.message = $translate.instant("ROLE_DELETE_CONFIRM_MESSAGE", {name: role._id});
    }]}).then(function () {
        PrimaGazRoleSrv.delete(role._id, function (data) {
        if (data.success) {
          Notification($translate.instant("ALERT_ROLE_DELETED", {name: role._id}));
          $scope.loadRoles();
          $scope.tableState.pagination.start = 0;
        } else {
          console.log(data.error);
        }
      }, function (err) {

      });
    }, function (reason) {

    });
  };
});
