/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
angular.module('app').controller('adminAssetEditCtrl', function ($scope,$rootScope, $stateParams,$state, $translate, PrimaGazAssetSrv, ngDialog, Notification) {
    $scope.asset = {};


    var now = moment().clone();
    var tday = moment().clone().endOf('day');


    $scope.pickerDay = {
        format: "DD/MM/YYYY",
        position: "top left",
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        maxDate: tday,
        getDate: function (date) {
            if (date) {
                $scope.asset.useDate = date.add(moment().utcOffset(), 'minutes');

            } else{
                if ($scope.asset && $scope.asset.useDate)
                    delete $scope.asset.useDate;

            }
        }
    };
    var query = {
        query: {
            _id: $stateParams.assetId
        },
        options: {
            populate: [
                {
                    path: '_company_owner',
                    select: 'name'
                }
            ]

        }
    };
    PrimaGazAssetSrv.query(query,1,1, function (response) {
        $scope.asset = response.result[0];
        if ($scope.asset && $scope.asset.useDate && $scope.asset.useDate != null) {
            $scope.pickerDay.init = moment($scope.asset.useDate).clone();
        }
    }, function (err) {
        console.log(err);
    });
    /**
     * Save asset
     */
    $scope.save = function () {
        PrimaGazAssetSrv.updateById($scope.asset._id, $scope.asset, function (response) {
            if(response.success){
                Notification.success({
                    title : $translate.instant("UPDATE"),
                    message : $translate.instant("ALERT_ASSET_UPDATED",{name:$scope.asset.name})
                });
                $state.go('app.admin.assets');
                $scope.asset  = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.asset.name +"!"
                });
                $scope.asset  = {};
            }
        }, function (err) {
            var msg = "";
            if( err.error.errorString){
                msg = err.error.errorString;
            }
            Notification.error({
                title: "Erreur",
                message : msg
            });
        })
    };


    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.asset = {};
        $state.go('app.admin.assets');
    }

});