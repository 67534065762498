/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
angular.module('app').controller('adminSidebarCtrl', function ($scope, $rootScope, $state) {
  $scope.listMenu = [];
  $scope.loadMenu = function () {
    var tab = [
      {
        name: "USERS",
        permission: $rootScope.checkPermission('USER', 'R'),
        route: 'app.admin.users',
        icon: "fa fa-user"
      },
        {
        name: "COMPANIES",
        permission: $rootScope.checkPermission('COMPANY', 'R'),
        route: 'app.admin.companies',
        icon: "fa fa-building"
      },
      {
        name: "ASSETS",
        permission: $rootScope.checkPermission('ASSET', 'R'),
        route: 'app.admin.assets',
        icon: 'fa fa-car'
      },


      {
        name: "ROLES",
        permission: $rootScope.checkPermission('ROLE', 'R', 'X'),
        route: 'app.admin.roles',
        icon: 'fa fa-user-circle'
      }

    ];
    angular.forEach(tab, function (item) {
      if (item.permission) {
        $scope.listMenu.push(item);
      }
    });
    if ($scope.listMenu && $scope.listMenu.length > 0) {
      $state.go($scope.listMenu[0].route);
    }
  };
  $scope.loadMenu();
});
