angular.module('app').controller('formTypeEditCtrl', function ($scope, $rootScope, $stateParams,$state,$translate,Notification,constantSrv,PrimaGazFormTypeSrv) {
    //Load GeoDataType from database
    $scope.geoDataType = {};
    var query = {
        query: {
            _id: $stateParams.formTypeId
        },
        options: {
            populate: [
                {
                    path: '_company_owner',
                    select: 'name'
                }
            ]

        }

    };
    PrimaGazFormTypeSrv.query(query, 1, 1, function (response) {
        $scope.geoDataType = response.result[0];
    }, function (err) {
        console.log(err);
    });
    //Init
    $scope.geoDataFieldTypes = constantSrv.getGeoDataInputTypes();
    $scope.pickerDay = {
        format : "DD/MM/YYYY",
        position: "top left",
        autoTrans : false,
        enableTime : false,
        enableDate : true,
        enableMinutes : false,
        defaultView : "DATE",
        getDate : function (date) {
            if(date){
                $scope.field.value = date.format($scope.pickerDay.format);
            }else{
                $scope.field.value = "";
            }
        }
    };
    $scope.geoDataType = {};
    $scope.geoDataType.form = {};
    $scope.geoDataType.form.fields = [];
    $scope.field = {
        title : "",
        type : $scope.geoDataFieldTypes[0].value,
        value : "",
        name: "",
        required : false,
        choices: []
    };
    $scope.choice = "";
    $scope.field.choices = [];
    $scope.editMode = false;
    //Controller Functions
    $scope.addField = function () {
        if (!$scope.editMode) {
            if ($scope.field.title !== '') {
                $scope.field.id = $scope.geoDataType.form.fields.length;
                $scope.geoDataType.form.fields.push($scope.field);
                $scope.field = {
                    title: "",
                    type: $scope.geoDataFieldTypes[0].value,
                    name: "",
                    value: "",
                    required: false,
                    choices: []
                };
                $scope.choice = ""
            }
            else {
                Notification.error("Veuillez ajouter un titre")
            }
        } else {
            if ($scope.field.title !== '') {
                $scope.geoDataType.form.fields[$scope.editIndex] = $scope.field;
                $scope.field = {
                    title: "",
                    type: $scope.geoDataFieldTypes[0].value,
                    value: "",
                    name: "",
                    required: false,
                    choices: []
                };
                $scope.editMode = false;
            }
            else{
                Notification.error("Veuillez ajouter un titre")
            }
        }
    };
    $scope.addChoice = function () {
        if(angular.isUndefined($scope.field.choices)){
            $scope.field.choices = [];
        }
        $scope.field.choices.push($scope.choice);
        $scope.choice = "";
    };
    $scope.deleteChoice = function (index) {
        $scope.field.choices.splice(index,1);
    };
    $scope.deleteField = function (field) {
        var index = $scope.geoDataType.form.fields.indexOf(field);
        console.log($scope.geoDataType.form.fields);
        $scope.geoDataType.form.fields.splice(index,1);
        for(var i = index; i< $scope.geoDataType.form.fields.length; i++){
            $scope.geoDataType.form.fields[i].id = $scope.geoDataType.form.fields[i].id - 1
        }

        if($scope.field){
            $scope.pickerDay.init = moment(field.value).clone();
        }

    };
    $scope.editField = function (field) {
            $scope.field = field;
            $scope.editIndex = $scope.geoDataType.form.fields.indexOf(field);
            if (field.type === "date" && field.value !== "") {
                $scope.pickerDay.init = moment(field.value, $scope.pickerDay.format).clone();
            }
            $scope.editMode = true;
    };
    function swapIndex(arr, a, b) {
        var tmp = arr[b];
        arr[b] = arr[a];
        arr[a] = tmp;

        return arr;
    }
    $scope.orderField = function(action, field){
        var order = field.id;
        var newOrder = -1;
        var index = $scope.geoDataType.form.fields.indexOf(field);
        if(action === "up"){
            newOrder = order - 1;
            $scope.geoDataType.form.fields[index-1].id = order;
        }else{
            newOrder =  order + 1;
            $scope.geoDataType.form.fields[index+1].id = order;
        }
        $scope.geoDataType.form.fields[index].id = newOrder;
        $scope.geoDataType.form.fields = swapIndex($scope.geoDataType.form.fields, order, newOrder);

    };
    $scope.saveGeoData = function () {

            PrimaGazFormTypeSrv.updateById($scope.geoDataType._id, $scope.geoDataType, function (data) {
            if(data.success){
                Notification($translate.instant("FORM_TYPE_UPDATED"));
                $state.go('app.fiche.formType');
            }else{
                console.log(data.error)
            }
        })
    }

});