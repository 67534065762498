/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
angular.module("app").controller('adminCtrl', function ($scope, $rootScope, constantSrv, $state, PrimaGazRoleSrv, PrimaGazCountrySrv, PrimaGazCompanySrv, PrimaGazHelperSrv) {
    PrimaGazRoleSrv.query({}, 10000, 1, function (response) {
    if (response.success) {
      $scope.roles = response.result;
    }
  });
    PrimaGazCompanySrv.query({}, 10000, 1, function (response) {
    if (response.success) {
      $scope.companies = response.result;
    }
  });
    PrimaGazCountrySrv.query({}, 1000, 0, function (res) {
    $scope.countries = res.result;
  });
    PrimaGazHelperSrv.getEnums(function (response) {
        $scope.ENUMS = response;
    });

});
