/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
angular.module('app').controller('adminAssetCtrl', function ($scope, $rootScope, $state, $translate, PrimaGazAssetSrv, ngDialog, Notification) {
  $scope.asset = {};
  $scope.itemsByPage = 5;
  $scope.search = {};


  $scope.loadAssets = function (tableState, ctrl) {
    if (!$scope.stCtrl && ctrl) {
      $scope.stCtrl = ctrl;
    }

    if (!tableState && $scope.stCtrl) {
      $scope.stCtrl.pipe();

      return;
    }
    var start = 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
    var number = $scope.itemsByPage;
    var query = {
      options: {populate:
      [
        {
          path: '_company_owner',
          select: 'name'
        }
      ]

      }
    };
    if (tableState.pagination) {
      var pagination = tableState.pagination;
      number = pagination.number || $scope.itemsByPage;
      start = pagination.start / number + 1 || 1;
    }
    if (tableState.sort && tableState.sort.predicate) {
      query.options.sortBy = {};

      var predicate = tableState.sort.predicate;
      query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
    }

    var requery = {};

    if (tableState.search && tableState.search.predicateObject) {
      if (tableState.search.predicateObject.name) {
        requery.name = {$regex: tableState.search.predicateObject.name, $options: "i"};
      }
        if (tableState.search.predicateObject.type) {
            requery.type = {$regex: tableState.search.predicateObject.type, $options: "i"};
        }
        if (tableState.search.predicateObject.model) {
            requery.model = {$regex: tableState.search.predicateObject.model, $options: "i"};
        }


    }
    if ($scope.search) {
      if ($scope.search._company_owner) {
        requery._company_owner = $scope.search._company_owner;
      }

     }
    query.query = requery;

      PrimaGazAssetSrv.query(query, number, start, function (data) {
      if (data.success) {
        $scope.assets = data.result;
        $scope.assetCount = data.total_count;
        tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
        tableState.pagination.totalItemCount = data.total_count;
        $scope.tableState = tableState;
      } else {
        console.error(data.error);
      }
    }, function (data) {
      console.log(data);
    });
  };
    /* delete asset*/
  $scope.remove = function (asset) {
    ngDialog.openConfirm({template: 'app/commons/dialogs/confirm.dlg.html', overlay: true, showClose: true, controller: ['$scope', function ($scope) {
                // controller logic
      $scope.title = $translate.instant("ASSET_DELETE_CONFIRM_TITLE", {name: asset.name});
      $scope.message = $translate.instant("ASSET_DELETE_CONFIRM_MESSAGE", {name: asset.name});
    }]}).then(function () {
        PrimaGazAssetSrv.delete(asset._id, function (data) {
        if (data.success) {
          Notification($translate.instant("ALERT_ASSET_DELETED", {name: asset.name}));
          $scope.loadAssets();
          $scope.tableState.pagination.start = 0;
        } else {
          console.log(data.error);
        }
      }, function (err) {

      });
    }, function (reason) {

    });
  };
});
