/**
 * Project: PrimaGaz
 * Created by Houcine yousfi 21/06/2018.
 */
var config_dev = {
  APP_NAME: "PRIMAGAZ",
  APP_VERSION: "v0.0.2",
  API_URI: "http://localhost:5000/api/v1.0",
    "LBS_WMS":"https://tcfleet.ngi.tn/bgis/wms?format=png",
    "BND_URI":"https://tcfleet.ngi.tn/bgis/bnd"

};

var config_pre_prod = {
  APP_NAME: "PRIMAGAZ",
  APP_VERSION: "v0.0.2",
  API_URI: "http://10.168.1.100:9100/api/v1.0",
    "LBS_WMS":"https://tcfleet.ngi.tn/bgis/wms?format=png",
    "BND_URI":"https://tcfleet.ngi.tn/bgis/bnd"

};

var config_primagaz = {
  APP_NAME: "PRIMAGAS",
  APP_VERSION: "v0.0.2",
    API_URI: "https://primagaz.ngi.tn/api/v1.0",
    "LBS_WMS":"https://primagaz.ngi.tn/bgis/wms?format=png",
    "BND_URI":"https://primagaz.ngi.tn/bgis/bnd"


};

angular.module("app").constant("CONFIG", config_pre_prod);

angular.module("app").config([
  '$translateProvider', "$provide", "ngDialogProvider", "NotificationProvider",
  function ($translateProvider, $provide, ngDialogProvider, NotificationProvider) {
    $translateProvider.useSanitizeValueStrategy('escapeParameters');
    $translateProvider.useStaticFilesLoader({
      files: [{
        prefix: 'i18n/',
        suffix: '.json'
      }, {
        prefix: 'i18n/server/',
        suffix: '.json'
      }]
    });
    $translateProvider.preferredLanguage('fr');
    $translateProvider.forceAsyncReload(true);

    NotificationProvider.setOptions({
      delay: 10000,
      startTop: 20,
      startRight: 10,
      verticalSpacing: 20,
      horizontalSpacing: 20,
      positionX: 'right',
      positionY: 'bottom'
    });

    ngDialogProvider.setDefaults({
      className: 'ngdialog-theme-default'
    });
  }
]);
