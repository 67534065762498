/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */

angular.module('app').controller('formsCtrl', function ($scope, $rootScope, $state, $translate, PrimaGazCompanySrv,PrimaGazUserSrv,
                                                           PrimaGazFormSrv, PrimaGazFormTypeSrv, ngDialog, Notification) {
    $scope.itemsByPage = 5;
    $scope.search = {};

    PrimaGazCompanySrv.query({}, 100, 1, function (response) {
        if (response.success) {
            $scope.companies = response.result;
        }
    });
    PrimaGazUserSrv.query({}, 100, 1, function (response) {
        if (response.success) {
            $scope.users = response.result;
        }
    });
    PrimaGazFormTypeSrv.query({}, 100, 1, function (response) {
        if (response.success) {
            $scope.types = response.result;
        }
    });

    $scope.loadFormsList = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options = {
            populate: [

                {
                    path: '_type',
                    select: 'name'

                }
                ,
                {
                    path: '_user',
                    select: 'last_name first_name'

                }
            ],
            sortBy: {creation_dt: -1}

        };

        var query = {};

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};

            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }

        if (tableState.search) {

            if ($scope.search._user) {
                query._user = $scope.search._user;


            }
            if ($scope.search._type) {
                query._type = $scope.search._type;
            }


        }



        PrimaGazFormSrv.query({query: query, options: options}, number, start, function (response) {
            if (response.success) {
                $scope.formList = response.result;


                $scope.listCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
            }
        });
    };

    $scope.removeList = function (item) {
        ngDialog.openConfirm({template: 'app/commons/dialogs/confirm.dlg.html', overlay: true, showClose: true, controller: ['$scope', function ($scope) {
            // controller logic
            $scope.title = $translate.instant("DELETE_FORM_CONFIRM_TITLE");
            $scope.message = $translate.instant("DELETE_FORM_CONFIRM_MSG", {});
        }]}).then(function (value) {
            PrimaGazFormSrv.delete(item._id, function (data) {
                if (data.success) {
                    Notification($translate.instant("FORM_DELETED", {type: item.name}));
                    $scope.loadFormsList();
                    $scope.tableState.pagination.start = 0;
                } else {
                    console.log(data.error);
                }
            }, function (err) {

            });
        }, function (reason) {

        });
    };
});
