/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */

angular.module("app.services").service('PrimaGazHelperSrv', function ($http, $rootScope, $window, AbstractApiSrv, PRIMA_GAZ_SERVICES, $injector,  $timeout) {
  function PrimaGazHelperSrv() {

  }
  PrimaGazHelperSrv.prototype.getEnums = function (success, error) {
    $http.get($rootScope.config.API_URI + '/enums').then(function (response) {
            // this callback will be called asynchronously
            // when the response is available
      if (response && response.data && response.data.success) {
        success(response.data.result);
      } else if (!response.data.success && response.data.error) {
        success(response.data);
      } else {
        error(response);
      }
    }, function (data) {
      error(data);
    });
  };

  return new PrimaGazHelperSrv();
});
