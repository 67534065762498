/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */

angular.module("app.services").factory('Excel', function ($window) {
   var service = {};

    var uri='data:application/vnd.ms-excel;base64,',

        template='<html lang="fr" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>\n</head><body>\n' +

            '              <p>\n' +
            '                    <img src=\'http://cdn.ngi.tn/images/logo_aireliquide.png\'  alt=\'\' width="30%">\n' +
            '               </p> <p>&nbsp;</p> \n' +
            '\n' +

            '          <table border=\'1\' >{table}</table></body></html>',


         template2='<html lang="fr" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>\n</head><body>\n' + '<table border=\'1\' >{table}</table></body></html>',

        base64=function(s){return $window.btoa(unescape(encodeURIComponent(s)));},
        format=function(s,c){return s.replace(/{(\w+)}/g,function(m,p){return c[p];})};

   // return {
    service.tableToExcel=function(tableId,worksheetName,templateChoice){
           // var table=$(tableId),
            var table= angular.element(tableId),
                ctx={worksheet:worksheetName+'-'+ new Date(),//moment().format('YYYYMMDDHHmmSS'),
                    table:table.html()};

                if(!templateChoice || templateChoice==undefined || templateChoice==false)
                var href=uri+base64(format(template,ctx));
            else
                href=uri+base64(format(template2,ctx));


            return href;
        }
  //  };


    return service;
});
