/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */

angular.module('app').controller('formDetailsCtrl', function ($scope, $rootScope, $state, $translate,$stateParams,
                                                           PrimaGazFormSrv,  ngDialog, Notification) {
    $scope.poiProperties =[]
    $scope.isVisibleMap = false;

    $scope.geoData = {};


    angular.extend($scope, {
        events: {
            map: {
                enable: ['click', 'drag', 'blur', 'touchstart','zoomend','moveend'],
                logic: 'emit'
            }
        },
        defaults: {
            zoomControlPosition: 'topleft',
            map: {
                minZoom:3
            }

        },

        center: {
            lat: 34.69543,
            lng: 9.38215,
            zoom: 6

        },
        markers: {},
        paths: {},


        controls: {},


        layers: {
            NGI: L.tileLayer.wms($rootScope.config.LBS_WMS, {
                name: 'LBS',
                attribution: 'Powered iaxeed'
            })
        }



    });

    var query = {
        query: {
            _id: $stateParams.formId
        },
        options :{
            populate: [

                {
                    path: '_type',
                    select: 'name'

                }
                ,
                {
                    path: '_user',
                    select: 'last_name first_name'

                }
            ]

        }
    };

    PrimaGazFormSrv.query( query, 1, 1, function (response) {
        $scope.geoData = response.result[0];
        $scope.poiProperties =[]

        if( $scope.geoData.geometry && $scope.geoData.geometry.coordinates && $scope.geoData.geometry.coordinates[0] && $scope.geoData.geometry.coordinates[1]) {
            $scope.isVisibleMap = true
            var marker = {
                coordinates: $scope.geoData.geometry.coordinates
            }
            var markers = {
                lng: marker.coordinates[0],
                lat: marker.coordinates[1],
                focus: true,
                icon: {
                    iconUrl: "app/assets/images/map_marker.png",
                    iconSize: [40, 40],
                    iconAnchor: [6, 15]
                }
            };
            $scope.center = {
                lng: marker.coordinates[0],
                lat: marker.coordinates[1],
                zoom: 17
            };
            $scope.markers.position = markers;
        }
        else{
            $scope.isVisibleMap = false;
        }

        for (var prop in $scope.geoData.properties) {
            if( $scope.geoData.properties.hasOwnProperty( prop ) ) {
                $scope.poiProperties.push({label:prop, value:$scope.geoData.properties[prop]})


            }
        }

    }, function (err) {
        console.log(err);
    });




});
