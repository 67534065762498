/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
angular.module('app').controller('adminCompanyAddCtrl', function ($scope, $rootScope, $state, $translate, PrimaGazCompanySrv, ngDialog, Notification) {
    $scope.company  = {};
    $scope.save = function () {
        $scope.company._ctry = $scope.company._ctry.code;
        PrimaGazCompanySrv.add($scope.company, function (data) {
            if (data.success) {
                Notification.success({
                    title : $translate.instant("ADD"),
                    message : $translate.instant("ALERT_COMPANY_ADDED",{name:$scope.company.name})
                });
                $state.go('app.admin.companies');
                $scope.company  = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.company.name +"!"
                });
                $scope.company  = {};
            }
        }, function (data) {
            console.log(data);
        });
    }

})