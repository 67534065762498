/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
angular.module('app').controller('sidebarFicheCtrl', function ($scope, $rootScope, $state) {
  $scope.listMenu = [];
  $scope.loadMenu = function () {
    var tab = [
      {
        name: "FORMS_TYPE",
        permission: $rootScope.checkPermission('GEODATA_TYPE', 'R'),
        route: 'app.fiche.formType',
        icon: "fa fa-tags"
      },
      {
        name: "FORMS_LIST",
        permission: $rootScope.checkPermission('GEODATA', 'R'),
        route: 'app.fiche.forms',
        icon: 'fa fa-map-marker'
      }

    ];

      angular.forEach(tab, function (item) {
      if (item.permission) {
        $scope.listMenu.push(item);
      }
    });
      if ($scope.listMenu && $scope.listMenu.length > 0) {
      $state.go($scope.listMenu[0].route);
    }
  };
  $scope.loadMenu();
});
