/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
angular.module("app.services").factory('constantSrv', function () {
  var service = {};

    service.getGeoDataInputTypes = function () {
        return [
            {
                name:"TEXTFIELD",
                value: "textfield"
            },{
                name:"EMAIL",
                value: "email"
            },{
                name:"PASSWORD",
                value: "password"
            },{
                name:"RADIOBUTTONS",
                value: "radiobuttons"
            },{
                name:"DROPDOWNLIST",
                value: "dropdownlist"
            },{
                name:"DATE",
                value: "date"
            },{
                name:"GEOMETRY",
                value: "geometry"
            },{
                name:"TEXTAREA",
                value: "textarea"
            },{
                name:"CHECKBOX",
                value: "checkbox"
            },{
                name:"HIDDEN",
                value: "hidden"
            }
        ]
    };


  service.getBrands = function () {
    return [
      {
        id: "ABARTHA",
        name: "Abartha"
      },
      {
        id: "ALFA_ROMEO",
        name: "Alfa Romeo"
      },
      {
        id: "ASTON_MARTIN",
        name: "Aston Martin"
      },
      {
        id: "AUDI",
        name: "Audi"
      },
      {
        id: "BENTLEY",
        name: "Bentley"
      },
      {
        id: "BMW",
        name: "BMW"
      },
      {
        id: "BUGATTI",
        name: "Bugatti"
      },
      {
        id: "CADILLAC",
        name: "Cadillac"
      },
      {
        id: "CAPARO",
        name: "Caparo"
      },
      {
        id: "CATERHAM",
        name: "Caterham"
      },
      {
        id: "CATERPILLAR",
        name: "Caterpillar"
      },
      {
        id: "CHEVROLET",
        name: "Chevrolet"
      },
      {
        id: "CHRYSLER",
        name: "Chrysler"
      },
      {
        id: "CITROEN",
        name: "Citroën"
      },
      {
        id: "CORVETTE",
        name: "Corvette"
      },
      {
        id: "DAF",
        name: "DAF"
      },
      {
        id: "DACIA",
        name: "Dacia"
      },
      {
        id: "DAEWOO",
        name: "Daewoo"
      },
      {
        id: "DAIHATSU",
        name: "Daihatsu"
      },
      {
        id: "DATSUN",
        name: "Datsun"
      },
      {
        id: "DELOREAN",
        name: "Delorean"
      },
      {
        id: "DODGE",
        name: "Dodge"
      },
      {
        id: "ARASH",
        name: "Arash"
      },
      {
        id: "FERRARI",
        name: "Ferrari"
      },
      {
        id: "FIAT",
        name: "Fiat"
      },
      {
        id: "FISKER",
        name: "Fisker"
      },
      {
        id: "FORD",
        name: "Ford"
      },
      {
        id: "HONDA",
        name: "Honda"
      },
      {
        id: "HUMMER",
        name: "Hummer"
      },
      {
        id: "HYUNDAI",
        name: "Hyundai"
      },
      {
        id: "INFINITI",
        name: "Infiniti"
      },
      {
        id: "IVECO",
        name: "Iveco"
      },
      {
        id: "ISUZU",
        name: "Isuzu"
      },
      {
        id: "JAGUAR",
        name: "Jaguar"
      },
      {
        id: "JCB",
        name: "JCB"
      },
      {
        id: "JEEP",
        name: "Jeep"
      },
      {
        id: "KIA",
        name: "KIA"
      },
      {
        id: "KTM",
        name: "KTM"
      },
      {
        id: "KOENIGSEGG",
        name: "Koenigsegg"
      },
      {
        id: "LADA",
        name: "Lada"
      },
      {
        id: "LAMBORGHINI",
        name: "Lamborghini"
      },
      {
        id: "LANCIA",
        name: "Lancia"
      },
      {
        id: "LAND_ROVER",
        name: "Land Rover"
      },
      {
        id: "LEXUS",
        name: "Lexus"
      },
      {
        id: "LIGIER",
        name: "Ligier"
      },
      {
        id: "LINCOLN",
        name: "Lincoln"
      },
      {
        id: "LOTUS",
        name: "Lotus"
      },
      {
        id: "MAHINDRA",
        name: "Mahindra"
      },
      {
        id: "MARTINI",
        name: "Martini"
      },
      {
        id: "MAN",
        name: "MAN"
      },
      {
        id: "MASERATI",
        name: "Maserati"
      },
      {
        id: "MAYBACH",
        name: "Maybach"
      },
      {
        id: "MAZDA",
        name: "Mazda"
      },
      {
        id: "MCLAREN",
        name: "MCLaren"
      },
      {
        id: "MERCEDES",
        name: "Mercedes"
      },
      {
        id: "MG",
        name: "MG"
      },
      {
        id: "MINI",
        name: "Mini"
      },
      {
        id: "MITSUBISHI",
        name: "Mitsubishi"
      },
      {
        id: "MORGAN",
        name: "Morgan"
      },
      {
        id: "NISSAN",
        name: "Nissan"
      },
      {
        id: "NOBLE",
        name: "Noble"
      },
      {
        id: "OM",
        name: "OM"
      },
      {
        id: "OPEL",
        name: "Opel"
      },
      {
        id: "PAGANI",
        name: "Pagani"
      },
      {
        id: "PEUGEOT",
        name: "Peugeot"
      },
      {
        id: "PONTIAC",
        name: "Pontiac"
      },
      {
        id: "PORSCHE",
        name: "Porsche"
      },
      {
        id: "PROTON",
        name: "Proton"
      },
      {
        id: "RENAULT",
        name: "Renault"
      },
      {
        id: "ROLLS_ROYCE",
        name: "Rolls Royce"
      },
      {
        id: "ROVER",
        name: "Rover"
      },
      {
        id: "SAAB",
        name: "Saab"
      },
      {
        id: "SSANGYONG",
        name: "SsangYong"
      },
      {
        id: "SEAT",
        name: "Seat"
      },
      {
        id: "SCANIA",
        name: "Scania"
      },
      {
        id: "SKODA",
        name: "Skoda"
      },
      {
        id: "SMART",
        name: "Smart"
      },
      {
        id: "SPYKER",
        name: "Spyker"
      },
      {
        id: "SUBARU",
        name: "Subaru"
      },
      {
        id: "SUZUKI",
        name: "Suzuki"
      },
      {
        id: "TATA",
        name: "TATA"
      },
      {
        id: "TOYOTA",
        name: "Toyota"
      },
      {
        id: "VAUXHALL",
        name: "Vauxhall"
      },
      {
        id: "VOLKSWAGEN",
        name: "Volkswagen"
      },
      {
        id: "VOLVO",
        name: "Volvo"
      },
      {
        id: "WALLYSCAR",
        name: "Wallyscar"
      },
      {
        id: "OTHERS",
        name: "Autres"
      }
    ];
  };

  return service;
});
