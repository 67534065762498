/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
var PRIMA_GAZ_SERVICES = [

    {name: "PrimaGazUserSrv", path: "user"},
    {name: "PrimaGazCompanySrv", path: "company"},
    {name: "PrimaGazRoleSrv", path: "role"},
    {name: "PrimaGazAssetSrv", path: "asset"},
    {name: "PrimaGazFormTypeSrv", path: "geodatatype"},
    {name: "PrimaGazFormSrv", path: "geodata"},
    {name: "PrimaGazCompanySrv", path: "company"},
    {name: "PrimaGasAssetTypeSrv", path: "assettype"},
    {name: "PrimaGazCountrySrv", path: "country"},
    {name: "PrimaGazDeviceSrv", path: "device"},
    {name: "PrimaGazDeviceTypeSrv", path: "devicetype"}

];
/**
 * ad constant service for further use
 */
angular.module("app.services").constant("PRIMA_GAZ_SERVICES", PRIMA_GAZ_SERVICES);

// create services
PRIMA_GAZ_SERVICES.forEach(function (val) {
  angular.module("app.services").factory(val.name, ["AbstractApiSrv", "CONFIG", function (AbstractApiSrv, CONFIG) {
    var service = new AbstractApiSrv(val);
    service.setApiUri(CONFIG.API_URI);
    return service;
  }]);
});

