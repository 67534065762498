/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
angular.module("app.services").factory('loginSrv', function ($http, $rootScope, $window, jwtHelper) {
  var service = {};
  var loggedIn = false;

  service.login = function (user, success, error) {
    $http.post($rootScope.config.API_URI + '/login', user).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available

            // console.log("LOGIN ");
      if (response && response.data && response.data.success) {
                // console.log("LOGIN OK");
        $window.sessionStorage.token = response.data.result.token;

        $rootScope.SESSION = response.data.result;
        $window.sessionStorage.SESSION = JSON.stringify(response.data.result);
        loggedIn = true;

        success(response.data);
      } else {
        delete $window.sessionStorage.token;
        delete $window.sessionStorage.SESSION;
        loggedIn = false;

        error(response.data);
      }
    }, function (data) {
      error(data);
      delete $window.sessionStorage.token;

      loggedIn = false;
    });
  };

  service.logout = function (success) {
    delete $window.sessionStorage.token;
    delete $window.sessionStorage.SESSION;
    loggedIn = false;
    if (success) {
      success();
    }
  };

  service.setLoggedInStatus = function (logged) {
    loggedIn = logged;
  };
  service.isLoggedIn = function () {
    return loggedIn;
  };

    /*
     Reset password
     */
  service.forgetPassword = function (userData, success, error) {
    $http.post($rootScope.config.API_URI + '/forget', userData).then(function (response) {
      if (response && response.data && response.data.success) {
        success(response.data);
      }
    }), function (data) {
      error(data);
    };
  };
  return service;
});
