/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */

angular.module('app').controller('formTypeCtrl', function ($scope, $rootScope, $state, $translate, PrimaGazCompanySrv,
                                                           PrimaGazFormTypeSrv, PrimaGazUserSrv,PrimaGazAssetSrv, ngDialog, Notification) {
    $scope.itemsByPage = 5;
    $scope.search = {};

    PrimaGazCompanySrv.query({}, 100, 1, function (response) {
        if (response.success) {
            $scope.companies = response.result;
        }
    });
    PrimaGazAssetSrv.query({}, 1000, 1, function (response) {
        if (response.success) {
            $scope.assets = response.result;
        }
    });

    PrimaGazUserSrv.query({}, 100, 1, function (response) {
        if (response.success) {
            $scope.users = response.result;
        }
    });

    $scope.loadList = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options = {
            populate: [
                {
                    path: '_company_owner',
                    select: 'name'

                }
            ],
            sortBy: {creation_dt: -1}

        };

        var query = {};

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};

            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.name) {
                query["name"] = {'$regex': tableState.search.predicateObject.name, $options: '-i'}
            }
            if (tableState.search.predicateObject.description) {
                query["description"] = {'$regex': tableState.search.predicateObject.description, $options: '-i'}
            }

        }



        PrimaGazFormTypeSrv.query({query: query, options: options}, number, start, function (response) {
            console.log(response)
            if (response.success) {
                $scope.list = response.result;

                // console.log("sheets listt :",$scope.list)

                $scope.listCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
            }
        });
    };

    $scope.removeList = function (item) {
        ngDialog.openConfirm({template: 'app/commons/dialogs/confirm.dlg.html', overlay: true, showClose: true, controller: ['$scope', function ($scope) {
            // controller logic
            $scope.title = $translate.instant("DELETE_FORM_TYPE_CONFIRM_TITLE");
            $scope.message = $translate.instant("DELETE_FORM_TYPE_CONFIRM_MSG", {type: item.name});
        }]}).then(function (value) {
            PrimaGazFormTypeSrv.delete(item._id, function (data) {
                if (data.success) {
                    Notification($translate.instant("FORM_TYPE_DELETED", {type: item.name}));
                    $scope.loadList();
                    $scope.tableState.pagination.start = 0;
                } else {
                    console.log(data.error);
                }
            }, function (err) {

            });
        }, function (reason) {

        });
    };
});
