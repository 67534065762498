/**
 * Project: PrimaGaz
 * Created by  YOUSFI houcine 21/06/2018.
 */
angular
    .module('app')
    .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise("/login");

  $stateProvider
        .state('login', {
          url: '/login',
          templateUrl: 'app/pages/login/login.tpl.html',
          controller: 'loginCtrl'
        })
        .state('app', {
          url: '/app',
          templateUrl: 'app/pages/main.tpl.html',
          controller: 'mainCtrl',
          abstract: true
        })
        .state('app.home', {
          url: '/home',
          templateUrl: "app/pages/home/home.tpl.html",
          controller: "homeCtrl"
        })
        .state('app.fiche', {
          url: '/fiche',
          views: {
            sidebar: {
              templateUrl: "app/pages/home/fiche/sidebar/fiche.sidebar.tpl.html",
              controller: "sidebarFicheCtrl"
            },
            content: {
              templateUrl: "app/pages/home/fiche/fiche.tpl.html",
              controller: "ficheCtrl"
            }
          }
        })
        .state('app.profile', {
          url: '/profile',
          views: {

            content: {
              templateUrl: 'app/pages/home/profile/profile.tpl.html',
              controller: "profileCtrl"
            }
          }
        })
        .state('app.admin', {
          url: '/admin',
          views: {
            sidebar: {
              templateUrl: "app/pages/home/admin/sidebar/admin.sidebar.tpl.html",
              controller: "adminSidebarCtrl"
            },
            content: {
              templateUrl: "app/pages/home/admin/admin.tpl.html",
              controller: "adminCtrl"
            }
          }

        })


        .state('app.admin.users', {
          url: '/users',
          templateUrl: 'app/pages/home/admin/users/users.tpl.html',
          controller: "adminUserCtrl"
        })
        .state('app.admin.users.add', {
          url: '/add',
          templateUrl: 'app/pages/home/admin/users/user.add.tpl.html',
          controller: "adminUserAddCtrl"
        })

        .state('app.admin.users.edit', {
          url: '/edit/:userId',
          templateUrl: 'app/pages/home/admin/users/user.edit.tpl.html',
          controller: "adminUserEditCtrl"
        })
        .state('app.admin.assets', {
          url: '/assets',
          templateUrl: 'app/pages/home/admin/assets/assets.tpl.html',
          controller: "adminAssetCtrl"
        })
        .state('app.admin.assets.add', {
          url: '/add',
          templateUrl: 'app/pages/home/admin/assets/asset.add.tpl.html',
          controller: "adminAssetAddCtrl"
        })
        .state('app.admin.assets.edit', {
          url: '/edit/:assetId',
          templateUrl: 'app/pages/home/admin/assets/asset.edit.tpl.html',
          controller: "adminAssetEditCtrl"
        })



        .state('app.admin.roles', {
          url: '/roles',
          templateUrl: 'app/pages/home/admin/roles/roles.tpl.html',
          controller: "adminRoleCtrl"
        })
        .state('app.admin.roles.add', {
          url: '/add',
          templateUrl: 'app/pages/home/admin/roles/role.add.tpl.html',
          controller: "adminRoleAddCtrl"
        })
        .state('app.admin.roles.edit', {
          url: '/edit/:roleId',
          templateUrl: 'app/pages/home/admin/roles/role.edit.tpl.html',
          controller: "adminRoleEditCtrl"
        })


      .state('app.admin.companies', {
          url: '/companies',
          templateUrl: 'app/pages/home/admin/companies/company.tpl.html',
          controller: "adminCompanyCtrl"
        })
        .state('app.admin.companies.add', {
          url: '/add',
          templateUrl: 'app/pages/home/admin/companies/company.add.tpl.html',
          controller: "adminCompanyAddCtrl"
        })
        .state('app.admin.companies.edit', {
          url: '/edit/:companyId',
          templateUrl: 'app/pages/home/admin/companies/company.edit.tpl.html',
          controller: "adminCompanyEditCtrl"
        })

       .state('app.fiche.formType', {
         url: '/formType',
         templateUrl: 'app/pages/home/fiche/formType/formType.tpl.html',
         controller: "formTypeCtrl"
       })
      .state('app.fiche.formType.add', {
          url: '/add',
          templateUrl: 'app/pages/home/fiche/formType/formType.add.tpl.html',
          controller: "formTypeAddCtrl"
      })
      .state('app.fiche.formType.edit', {
          url: '/edit/:formTypeId',
          templateUrl: 'app/pages/home/fiche/formType/formType.edit.tpl.html',
          controller: "formTypeEditCtrl"
      })

        .state('app.fiche.forms', {
          url: '/forms',
          templateUrl: 'app/pages/home/fiche/forms/form.tpl.html',
          controller: "formsCtrl"
        })
      .state('app.fiche.forms.show', {
          url: '/details/:formId',
          templateUrl: 'app/pages/home/fiche/forms/form.show.tpl.html',
          controller: "formDetailsCtrl"
      })



}
